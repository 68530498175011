/* --------------------------------------------------- Table ---------------------------------------------------  */

.tg {
    border-collapse: separate;
    border-spacing: 0;
}
.tg td {
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
}
.tg th {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 5px 5px;
    word-break: normal;
}

.tg .tg-lboi {
    border-color: inherit;
    text-align: center;
    vertical-align: middle;
}
.tg .tg-9wq8 {
    border-color: inherit;
    text-align: center;
    vertical-align: middle;
}
.tg .tg-0pky {
    border-color: inherit;
    text-align: center;
    vertical-align: top;
}

thead {
    background-color: #f2f2f2;
}

.createForm table {
    border-collapse: separate; /* Don't collapse */
}
.createForm table th,
.createForm table td {
    border: 1px solid #d9d9d9;
}

.createForm table tfoot th,
.createForm table tfoot td {
    border: none;
}

input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sticky-table input[type="text"],
.sticky-table input[type="number"] {
    background: #ffffff;
    width: 150px;
    border: 1px solid #2bbecb;
    border-radius: 8px;
    height: 36px;
    padding: 5px 5px !important;
    color: #888888;
    font-size: 11px !important;
    text-align: center !important;
}

.sticky-table thead th {
    background-color: #f2f2f2 !important;
    color: #697580;
    font-weight: 500;
    font-size: 12px;
    z-index: 1;
}

.sticky-table thead > tr:first-child > th:nth-child(1),
.sticky-table tfoot > tr:first-child > th:nth-child(1),
.sticky-table tbody > tr > th:nth-child(1) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 0;
}

.sticky-table tbody > tr > th:nth-child(1) input[type="text"] {
    width: 80px !important;
}

.sticky-table thead > tr:first-child > th:nth-child(2),
.sticky-table tfoot > tr:first-child > th:nth-child(2),
.sticky-table tbody > tr > th:nth-child(2) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 43px;
}

.sticky-table thead > tr:first-child > th:nth-child(3),
.sticky-table tfoot > tr:first-child > th:nth-child(3),
.sticky-table tbody > tr > th:nth-child(3) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 135px;
}

.sticky-table tbody > tr > th:nth-child(3) input[type="text"] {
    width: 125px;
}

.sticky-table thead > tr:first-child > th:nth-child(4),
.sticky-table tfoot > tr:first-child > th:nth-child(4),
.sticky-table tbody > tr > th:nth-child(4) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 273px;
}

.sticky-table tbody > tr > th:nth-child(4) input[type="text"] {
    width: 70px;
}

.sticky-table thead > tr:first-child > th:nth-child(5),
.sticky-table tfoot > tr:first-child > th:nth-child(5),
.sticky-table tbody > tr > th:nth-child(5) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 356px;
}

.sticky-table thead > tr:first-child > th:nth-child(5),
.sticky-table tfoot > tr:first-child > th:nth-child(5),
.sticky-table tbody > tr > th:nth-child(5) input[type="text"] {
    width: 55px;
}

.sticky-table thead > tr:first-child > th:nth-child(6),
.sticky-table tfoot > tr:first-child > th:nth-child(6),
.sticky-table tbody > tr > th:nth-child(6) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 424px;
}

.sticky-table thead > tr:first-child > th:nth-child(6),
.sticky-table tfoot > tr:first-child > th:nth-child(6),
.sticky-table tbody > tr > th:nth-child(6) input[type="text"] {
    width: 60px;
}

.sticky-table thead > tr:first-child > th:nth-child(7),
.sticky-table tfoot > tr:first-child > th:nth-child(7),
.sticky-table tbody > tr > th:nth-child(7) {
    position: sticky;
    background-color: #ffffff;
    z-index: 11 !important;
    left: 497px;
}

.sticky-table thead > tr:first-child > th:nth-child(7),
.sticky-table tfoot > tr:first-child > th:nth-child(7),
.sticky-table tbody > tr > th:nth-child(7) input[type="text"] {
    width: 60px;
}

.child-tr th:nth-child(1),
.child-tr th:nth-child(2) {
    z-index: 1 !important;
}

.child-item th:nth-child(2) {
    padding-left: 40px;
}

tbody th {
    padding: 10px 5.5px !important;
}

.medium input[type="text"],
.medium input[type="number"] {
    width: 70px;
}

.small input[type="text"],
.small input[type="number"] {
    width: 60px;
}

.xsmall input[type="text"],
.xsmall input[type="number"] {
    width: 50px;
}

.disabled {
    background-color: #e0e0e0 !important;
}
