@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;0,800;1,600&display=swap");

* {
  font-family: "Poppins" !important;
}

.input-trucking {
  overflow-x: auto;
}

.input-trucking::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.input-trucking::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.input-trucking::-webkit-scrollbar-thumb {
  background-color: #7f57f1;
}

/* --------------------------------------------------- input ---------------------------------------------------  */

input[type="text"],
input[type="email"],
input[type="password"] {
  background: #ffffff;
  border: 1px solid #2bbecb;
  border-radius: 8px;
  padding: 14.56px 22.95px 15.44px 22.95px !important;
  color: #888888;
}

input[type="date"] {
  background: #ffffff;
  border: 1px solid #2bbecb;
  border-radius: 8px;
  padding: 2px !important;
  color: #888888;
}

.react-datepicker__tab-loop {
  position: fixed;
}

.btn,
.btn:hover,
.btn:focus {
  color: white;
  background-color: #7f57f1;
}

.btn-outline,
.btn-outline:hover,
.btn-outline:focus {
  background: #ffffff !important;
  border: 1px solid #7f57f1;
  border-radius: 8px;
  color: #7f57f1;
  text-transform: capitalize;
  font-weight: 500 !important;
}

.btn-transparent,
.btn-transparent:hover,
.btn-transparent:focus {
  background: transparent !important;
  border: none;
  min-width: 39.27px;
  color: #F07474;
  font-weight: 500;
  padding: 0;
  text-transform: capitalize !important;
}

.btn-pagination {
  background-color: transparent !important;
  border: none;
  min-width: 39.27px;
  color: #000;
  font-weight: 500;
  padding: 0;
  text-transform: capitalize !important;
  border-radius: 5px !important;

}

.btn-pagination-active {
  background-color: #D1C1D7 !important;
  border: none;
  min-width: 39.27px;
  color: #fff;
  font-weight: 500;
  padding: 0;
  text-transform: capitalize !important;
  border-radius: 5px !important;
}

.btn-pagination:hover,
.btn-pagination:focus {
  background: #D1C1D7 !important;
  border: none;
  min-width: 39.27px;
  color: #fff;
  font-weight: 500;
  padding: 0;
  text-transform: capitalize !important;
  border-radius: 5px !important;
}


.btn-edit,
.btn-edit:hover,
.btn-edit:focus {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;

  /* width: 86px; */
  height: 33px;

  border: 1px solid #E3E5E4;
  border-radius: 8px;

  background-color: transparent;
  color: #000337;

  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

/* --------------------------------------------------- Sidebar ---------------------------------------------------  */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}

#sidebar-wrapper {
  z-index: 1000;
  position: absolute;
  left: 0;
  width: 96px;
  height: 100%;
  padding: 12px 0px 12px 12px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar-nav {
  position: absolute;
  top: 234px;
  width: 100px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px;
}

#sidebar-wrapper ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px;
}

#sidebar-wrapper li {
  width: 46px;
  background-color: white;
  height: 46px;
  padding: 13px 14px;
  border-radius: 16px;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

#page-content-wrapper {
  position: absolute;
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #page-content-wrapper {
  position: relative;
  margin-right: 0;
  padding-left: 100px;
}