@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 100px;
    height: 100px;
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #7f57f1;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}


.alert {
    animation: 2s alert-fade ease-in-out;
    transition: 0.35s all;
    opacity: 0;
}

@keyframes alert-fade {
    0%,40% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}