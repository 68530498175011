.index-trucking-table thead{
    background-color: black;
}

.index-trucking-table th{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    
    letter-spacing: 0.08em;
    
    color: #697580;
    background-color: #F2F2F2;
}

.index-trucking-table td{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    
    color: #000337;
    background-color: #FDFDFD;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #E2E8F0;

} 

.modal {
    z-index: 1000;
}

.modal-box {
    width: 370px !important;
}